import * as buffer from "buffer";

window.Buffer = buffer.Buffer;

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

// /* Core CSS required for Ionic components to work properly */
// import "@ionic/vue/css/core.css";

// /* Basic CSS for apps built with Ionic */
// import "@ionic/vue/css/normalize.css";
// import "@ionic/vue/css/structure.css";
// import "@ionic/vue/css/typography.css";

// /* Optional CSS utils that can be commented out */
// import "@ionic/vue/css/padding.css";
// import "@ionic/vue/css/float-elements.css";
// import "@ionic/vue/css/text-alignment.css";
// import "@ionic/vue/css/text-transformation.css";
// import "@ionic/vue/css/flex-utils.css";
// import "@ionic/vue/css/display.css";

// /* Theme variables */
// import "./theme/variables.css";

import Chakra from "@chakra-ui/vue-next";
import { chakra, domElements } from "@chakra-ui/vue-system";
import { AxiaSolana, SolanaNetworks } from "./utils/solana.plugin";
import * as icons from "./utils/icons";
import theme from "./theme/extend";
import { createHead } from "@vueuse/head";

const head = createHead();

const app = createApp(App).use(IonicVue).use(router);
app.use(head);

app.use(Chakra, {
  extendTheme: theme,
  icons: {
    library: icons,
  },
});

app.use(AxiaSolana, {
  network: SolanaNetworks.devnet,
});

domElements.forEach((tag) => {
  app.component(`chakra.${tag}`, chakra(tag));
});

router.isReady().then(() => {
  app.mount("#app");
});
